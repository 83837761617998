<script>
import Layout from "../../layouts/main";
import Breadcrumb from "@/components/breadcrumb";
import Spinner from "vue-simple-spinner";
import * as cloneDeep from "lodash.clonedeep";
import * as moment from "moment";
import Swal from "sweetalert2";

import {
  FETCH_PAYMENT_LINK_DETAILS,
  FETCH_PAYMENT_LINK_TRANSACTIONS,
  DELETE_PAYMENT_LINKS,
  NOTIFICATION_PAYMENT_LINKS,
  GENERATE_QR_CODE
} from "@/state/actions.type";
import PaymentMethodView from '../../../components/payment-method-view.vue';
// import PaymentLinkForm from './payment-link-form.vue';

export default {
  components: {
    Layout,
    Breadcrumb,
    Spinner,
    PaymentMethodView,
    // PaymentLinkForm
  },
  data() {
    return {
      paymentMethodList:['CARD', 'WALLET', 'ALL'],
      pageDetails: null,
      pageTransactions: [],
      advancedFilterClosed: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filter: null,
      filters: {
        to: "",
        from: "",
        pmt_channel:[],
        pmt_method:[],
        status:[],
      },
      merchant_uuids:[],
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      refValueForFilter: "",
      // filterOptions: ['Merchant Order Ref', 'Channel Order Ref', 'PortOne Order Ref', 'Billing Name', 'Email', 'Phone Number'],
      currentSelectedFilter: "",
      totalTransactions: "",
      totalPayments: "",
      totalRevenue: "",
      pageRef: "",
      isAddPaymentLinkModalOpen: false,
      choosenPaymentMethods: 0,
      // isDetailsModalOpen: false,
      // selectedTrasactionData: null,
      fields: [
        // { key: "show_details", label: 'Options', tooltip: "Options" },
        { key: "createdAt", sortable: true, label: "views.payment_links.table.fields.date" },
        { key: "OrderRef", sortable: true, label: "views.payment_links.table.fields.order_ref" },
        { key: "status", sortable: true, label: "views.payment_links.table.fields.status" },
        { key: "ChannelName", sortable: true,label: "views.payment_links.table.fields.channel_name" },
        { key: "PaymentMethodKey", sortable: true, label: "views.payment_links.table.fields.payment_method" },
      ],
    };
  },
  filters: {
    date(value) {
        return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
    dateFormat(date) {
      return moment(date).format("Do MMMM YYYY hh:mm a");
    }
  },

  created() {
    this.pageRef = this.$route.query.pageRef;

    this.$store.dispatch(`paymentlinks/${FETCH_PAYMENT_LINK_DETAILS}`, {
      pageRef: this.pageRef,
      submerchantKey: this.$route.query.key
    }).then(() => {
      this.pageDetails = cloneDeep(this.paymentLinkDetailStore);
      this.choosenPaymentMethods = Object.keys(this.pageDetails.content.chosen_payment_methods).length;
      
      this.setTransactionData();
    }).catch((error) => {
      const err = JSON.parse(error.message);
      this.$notify({
        type: "error",
        text: err.message,
        closeOnClick: true,
      });
    });
  },

  // mounted() {
  //   this.selectedTrasactionData = cloneDeep(this.selectedTrasactionDataFromStore);
  // },

  computed: {
    paymentLinkDetailStore() {
      return this.$store.state.paymentlinks.pageDetails;
    },

    paymentLinkTransactions() {
      return this.$store.state.paymentlinks.pageTransactions;
    },

    isFetchingTransactions() {
      return this.$store.state.paymentlinks.isFetchingData;
    },

    // selectedTrasactionDataFromStore() {
    //   return this.$store.getters["transactions/getTransactionsDataForSelectedTransactionID"];
    // },
    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    items() {
      return [
        {
          text: this.$t('views.payment_links.title'),
          active: false,
          href: "/payment-links"
        },
        {
          text: this.$t('views.payment_links.details'),
          active: true,
        }
      ];
    },

    title() {
      return this.$t('views.payment_links.title');
    },

    statusText() {
      let text;
      switch (this.pageDetails.content.status) {
        case 'Created':
          text = 'views.payment_links.created';
          break;
        case 'Processing':
          text = 'views.payment_links.processing';
          break;
        case 'Expired':
          text = 'views.payment_links.expired';
          break;
        case 'Cancelled':
          text = 'views.payment_links.cancelled';
          break;
        case 'Success':
          text = 'views.payment_links.success';
          break;
        case 'Under Review':
          text = 'Under Review';
          break;
        default:
          text = this.pageDetails.content.status;
          break;
      }
      return text;
    }
  },
  watch: {
    perPage: function () {
      this.setTransactionData();
    },

    currentPage: function () {
      this.setTransactionData();
    },

    selectedTrasactionDataFromStore() {
      this.selectedTrasactionData = cloneDeep(this.selectedTrasactionDataFromStore);
    },
  },

  methods: {
    /**
     * Search the table data with search input
    //  */
    sortByDate(a, b, key) {
      if (key == "created_at") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    setTransactionData() {
      this.$store.dispatch(`paymentlinks/${FETCH_PAYMENT_LINK_TRANSACTIONS}`, {
        pageRef: this.pageRef
      }).then(() => {
        let result = this.paymentLinkTransactions;
        this.pageTransactions = cloneDeep(result.content);
      });
    },

    getlangcodefromcurr(currdata) {
      let language;
      if (currdata) {
        if (currdata === "VND") {
          language = "vi";
        } else if (currdata === "THB") {
          language = "th";
        } else {
          language = "en";
        }
      } else {
        language = "vi";
      }
      return language;
    },

    getFormattedNumber(numberValue, currencyValue) {
      let choosenLanguage = this.getlangcodefromcurr(currencyValue);
      let formattedValue = new Intl.NumberFormat(choosenLanguage, {
        style: "currency",
        currency: currencyValue,
        minimumFractionDigits: 2,
      }).format(numberValue);
      //console.log("currency value and langauge", formattedValue, choosenLanguage);
      return formattedValue;
    },

    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_links.ref_copy_success'),
        closeOnClick: true,
      });
    },
    onCopyQrSuccess() {
      this.$notify({
        type: "success",
        text: "QR Code Copied Successfully",
        closeOnClick: true,
      });
    },
    downloadQR() {
      const link = document.createElement('a');
      link.href = this.pageDetails.content.qrcode_link;
      link.download = 'QRCode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onCopySuccess1() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_links.copy_success'),
        closeOnClick: true,
      });
    },
    onCopySuccess2() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_links.page_ref_copy_success'),
        closeOnClick: true,
      });
    },
    deletePaymentLink(linkRef) {
      Swal.fire({
        title: this.$t('views.payment_links.delete_confirmation'),
        text:"Link Ref - "+ linkRef,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.delete'),
        cancelButtonText: this.$t('button.cancel'),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`paymentlinks/${DELETE_PAYMENT_LINKS}`, linkRef)
            .then((response) => {
              console.log(response);
              this.$notify({
                type: "success",
                text: this.$t('views.payment_links.delete_success'),
                closeOnClick: true,
              });
              Swal.fire(this.$t('common.deleted'), this.$t('views.payment_links.delete_success'), "success");
              setTimeout(() => {
                this.$store.dispatch(`paymentlinks/${FETCH_PAYMENT_LINK_DETAILS}`, {
                  pageRef: this.pageRef
                }).then(() => {
                  this.pageDetails = cloneDeep(this.paymentLinkDetailStore);
                });
              }, 500);
            })
            .catch((error) => {
              // error
              console.log("error", error);
              Swal.fire(this.$t('common.deleted'), this.$t('views.payment_links.delete_failed'), "error");
            });
        }
      });
    },
    generateQR() {
      this.$store.dispatch(`paymentlinks/${GENERATE_QR_CODE}`, {
        pageRef: this.pageRef
      }).then((response) => {
        this.pageDetails.content.qrcode_link = response.QRCodeImage;
        this.$notify({
          type: "success",
          text: "QR Code Generated Successfully",
          closeOnClick: true,
        });
      }).catch((error) => {
        // error
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      });
    },
    sendNotificationPaymentLink(linkRef) {
      this.$store
      .dispatch(`paymentlinks/${NOTIFICATION_PAYMENT_LINKS}`, linkRef)
      .then((response) => {
        console.log(response);
        this.$notify({
          type: "success",
          text: "Notification Successfully Sended",
          closeOnClick: true,
        });
      })
      .catch((error) => {
        // error
        console.log("error", error);
         this.$notify({
            type: "error",
            text: 'Failed To send Notification.',
            closeOnClick: true,
          });
      });
    },
    closePaymentLinkModal() {
      // this.isAddPaymentLinkModalOpen = false;
    },
    duplicatePage() {
      this.$router.push({ path: '/payment-links/create-payment-links', query: { pageRef: this.pageRef, duplicate: true }})
      // this.isAddPaymentLinkModalOpen = true;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <breadcrumb :items="items" :goBackTo="{ name: 'Payment Links' }" class="pb-2" />
    <div class="row" v-if="pageDetails">
      <div class="col-lg-12">
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="row">
                <!-- <div class="col-md-9" style="color: #252B3B;padding-bottom: 15px;font-size: 16px;font-weight: 500;">{{ pageDetails ? pageDetails.content.title : '' }}</div> -->
                <div class="col-md-12">
                  <!-- <b-dropdown variant="link" size="sm" id="social-share">
                    <template v-slot:button-content>
                      <button class="btn custom-btn">
                        <img src="@/assets/images/icon-share.svg" class="mb-1 mr-1" alt="share"> Share
                      </button>
                    </template>
                    <b-dropdown-item-button>
                      <ShareNetwork
                        network="facebook"
                        :url="pageDetails.content.page_link"
                        :title="pageDetails.content.title"
                      >
                        Share on Facebook
                      </ShareNetwork>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                      <ShareNetwork
                        network="whatsapp"
                        :url="pageDetails.content.page_link"
                        :title="pageDetails.content.title"
                      >
                        Share on Whatsapp
                      </ShareNetwork>
                    </b-dropdown-item-button>
                  </b-dropdown> -->
                  <!-- <span class="btn dropdown-toggle btn-link">
                    <button class="btn custom-btn" v-clipboard:copy="pageDetails ? pageDetails.content.page_link : ''" v-clipboard:success="onCopySuccess">
                      <img src="@/assets/images/icon-copy.svg" class="mb-1 mr-1" alt="copy"> Copy
                    </button>
                  </span> -->
                  <div class="d-flex justify-content-end align-items-start">
                      <button
                        v-if="pageDetails.content.notify_by_email || pageDetails.content.notify_by_phone" @click="sendNotificationPaymentLink(pageRef)"
                        class="btn btn-primary btn-sm"
                        style="margin-bottom: 25px;position: absolute;top: -55px;padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;"
                      >
                     Send Notification
                      </button>
                  </div>
                  <div class="banner-section">
                    <div class="banner-content">
                        <div style="font-size: 12px;color: #0000004d;">
                          Payment Link
                        </div>
                        <div style="font-size: 20px;color: #000;margin-top: 5px;">
                          {{ pageDetails.content.description ? pageDetails.content.description : `Payment for ${getFormattedNumber(pageDetails.content.amount, pageDetails.content.currency)}` }}
                        </div>
                        <div style="color: #00000080;margin-top: 10px; margin-bottom: 25px;font-weight: 325">
                          Copy and share the link to start accepting payment.
                        </div>
                        <div class="d-flex justify-content-start" style="align-items: center;">
                          <span class="text-chai" style="font-size: 16px;">Link : </span>
                            <p class="text-truncate mb-0" style="width: 350px;background: white;border-radius: 12px;border: 1px #DCDAD5 solid;padding: 13px 10px;margin-left: 5px;">
                              <a :href="pageDetails.content.link"  style="color: #060606; font-size: 16px;" target="_blank">{{ pageDetails.content.link }}</a>
                            </p>
                              <span 
                                v-show="pageDetails.content.link"
                                v-clipboard:copy="pageDetails.content.link"
                                v-clipboard:success="onCopySuccess1"
                                v-b-tooltip.hover.right
                                :title="$t('common.copy')"
                                style="cursor: pointer;background: black;border-radius: 12px;color: rgb(255, 255, 255);padding: 10px 16px;margin-left: 10px;font-size: 14px;">
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g id="Iconsax/Linear/copy">
                                  <path id="Vector" d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path id="Vector_2" d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  </svg>
                                </span>
                                <span style="padding-left: 10px;">Copy</span>
                              </span>
                            <span style="cursor: pointer;padding: 9px 15px;margin-left: 10px;background: rgb(245, 244, 243);border-radius: 12px;border: 1px solid rgb(220, 218, 213);font-size: 14px;" v-if="!isMasterMerchant" @click="duplicatePage">
                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g id="Iconsax/Linear/save2" opacity="0.7">
                              <path id="Vector" opacity="0.7" d="M12.89 5.87988H5.11C4.28599 5.88252 3.49648 6.21103 2.91381 6.79369C2.33114 7.37636 2.00264 8.16587 2 8.98988V20.3499C2 21.7999 3.04 22.4199 4.31 21.7099L8.24 19.5199C8.66 19.2899 9.34 19.2899 9.75 19.5199L13.68 21.7099C14.95 22.4199 15.99 21.7999 15.99 20.3499V8.98988C15.9907 8.58213 15.911 8.17824 15.7556 7.80127C15.6001 7.4243 15.372 7.08164 15.0841 6.79285C14.7962 6.50406 14.4543 6.2748 14.0779 6.11815C13.7014 5.9615 13.2978 5.88054 12.89 5.87988Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path id="Vector_2" opacity="0.7" d="M16 8.98988V20.3499C16 21.7999 14.96 22.4099 13.69 21.7099L9.76 19.5199C9.34 19.2899 8.66 19.2899 8.24 19.5199L4.31 21.7099C3.04 22.4099 2 21.7999 2 20.3499V8.98988C2 7.27988 3.4 5.87988 5.11 5.87988H12.89C14.6 5.87988 16 7.27988 16 8.98988Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path id="Vector_3" d="M22 5.11V16.47C22 17.92 20.96 18.53 19.69 17.83L16 15.77V8.99C16 7.28 14.6 5.88 12.89 5.88H8V5.11C8 3.4 9.4 2 11.11 2H18.89C20.6 2 22 3.4 22 5.11Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                            </svg>
                              <span class="text-black" style="padding-left: 10px; cursor: pointer;">Duplicate</span>
                            </span>
                            <span style="cursor: pointer;padding: 9px 15px;margin-left: 10px;background: rgb(245, 244, 243);border-radius: 12px;border: 1px solid rgb(220, 218, 213);font-size: 14px;" @click="generateQR" v-if="!pageDetails.content.qrcode_link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g id="Iconsax/Linear/scanbarcode" opacity="0.7">
                                <path id="Vector" d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9M15 2H17.5C19.99 2 22 4.01 22 6.5V9M22 16V17.5C22 19.99 19.99 22 17.5 22H16M9 22H6.5C4.01 22 2 19.99 2 17.5V15M10.5 7V9C10.5 10 10 10.5 9 10.5H7C6 10.5 5.5 10 5.5 9V7C5.5 6 6 5.5 7 5.5H9C10 5.5 10.5 6 10.5 7ZM18.5 7V9C18.5 10 18 10.5 17 10.5H15C14 10.5 13.5 10 13.5 9V7C13.5 6 14 5.5 15 5.5H17C18 5.5 18.5 6 18.5 7ZM10.5 15V17C10.5 18 10 18.5 9 18.5H7C6 18.5 5.5 18 5.5 17V15C5.5 14 6 13.5 7 13.5H9C10 13.5 10.5 14 10.5 15ZM18.5 15V17C18.5 18 18 18.5 17 18.5H15C14 18.5 13.5 18 13.5 17V15C13.5 14 14 13.5 15 13.5H17C18 13.5 18.5 14 18.5 15Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                            </svg>
                              <span class="text-black" style="padding-left: 10px; cursor: pointer;">Generate QR</span>
                            </span>
                        </div>
                        <div v-if="pageDetails.content.qrcode_link">
                          <div style="width:120px;position: absolute;top: 30px;right: 40px;">
                            <img :src="pageDetails.content.qrcode_link" class="w-100" alt="">
                            <b-btn size="sm" variant="primary" v-clipboard:copy="pageDetails.content.qrcode_link" v-clipboard:success="onCopyQrSuccess" v-b-tooltip.hover.bottom title="copy" class="" style="cursor: copy; padding: 3px 15px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none">
                                  <g id="Iconsax/Linear/copy">
                                  <path id="Vector" d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path id="Vector_2" d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                              </svg>
                            </b-btn>
                            <b-btn size="sm" variant="primary" class="float-right" style="cursor: pointer; padding: 3px 15px;" v-b-tooltip.hover.bottom title="download" @click="downloadQR">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g id="Iconsax/Linear/importcurve" clip-path="url(#clip0_1787_33717)">
                                <path id="Vector" d="M12.2373 6.73975C14.8623 6.96579 15.9341 8.31475 15.9341 11.2679L15.9341 11.3627C15.9341 14.622 14.6289 15.9272 11.3696 15.9272L6.62269 15.9272C3.36331 15.9272 2.05811 14.622 2.05811 11.3627L2.05811 11.2679C2.05811 8.33662 3.1154 6.98766 5.69665 6.74704M8.99977 1.7085L8.99977 11.1002" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path id="Vector_2" d="M11.4425 9.47412L8.99984 11.9168L6.55713 9.47412" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1787_33717">
                                <rect width="17.5" height="17.5" fill="white" transform="translate(0.249756 0.25)"/>
                                </clipPath>
                                </defs>
                              </svg>
                            </b-btn>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="top-section">
                    <div class="top-section-header">
                      <span style="font-size: 20px;color: #000000;">Payment Link Details</span>
                    </div>

                  </div>
                  <!-- <span class="btn dropdown-toggle btn-link">
                    <button v-if="pageDetails.content.notify_by_email || pageDetails.content.notify_by_phone" class="btn btn-primary" @click="sendNotificationPaymentLink(pageRef)">
                      Send Notification
                    </button>
                    <button class="btn custom-btn mx-2" v-if="!isMasterMerchant" @click="duplicatePage">
                      <i class="mdi mdi-content-duplicate"></i>{{ $t('views.payment_pages.duplicate') }}
                    </button>
                    <button class="btn custom-btn mr-2" @click="generateQR" v-if="!pageDetails.content.qrcode_link">
                      Generate QR
                    </button>
                    <button v-if="pageDetails.content.status == 'Created'" class="btn custom-btn" @click="deletePaymentLink(pageRef)">
                      {{ $t("button.cancel") }}
                    </button>
                  </span> -->
                </div>
              </div>
              <div class="mb-4 row rounded" v-if="pageDetails" style="font-size: 13px;">
                  <div class="col-md-12">
                    <table class="page-details-1" width="100%">
                      <colgroup>
                        <col width="50%">
                        <col width="50%">
                      </colgroup>
                      <tr style="border-bottom: 1px #DCDAD5 solid;" v-if="pageDetails.content.chosen_payment_methods && Object.keys(pageDetails.content.chosen_payment_methods).length > 0">
                        <td>Payment Methods</td>
                        <td><payment-method-view class="col-md-12" hideTitle :selectedPaymentMethods="pageDetails.content.chosen_payment_methods" /></td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.link_status') }}:</td>
                        <td>
                          <b-badge
                            class="font-size-12"
                            style="padding: 9px 15px; border-radius: 8px;"
                            :class="{
                              'badge-soft-warning': pageDetails.content.status === 'Processing' || pageDetails.content.status === 'Created',
                              'badge-soft-danger': pageDetails.content.status === 'Expired',
                              'badge-soft-danger': pageDetails.content.status === 'Cancelled',
                              'badge-soft-danger': pageDetails.content.status === 'Failed',
                              'badge-soft-success': pageDetails.content.status == 'Success',
                              'badge-orange': pageDetails.content.status == 'Under Review',
                              'refunded': pageDetails.content.status === 'Refunded',
                              'partial-refund': pageDetails.content.status === 'Partially Refunded'
                            }"
                          >
                          {{ $t(statusText) }}
                          </b-badge>
                          <span style="text-decoration: underline;padding-left: 25px; cursor: pointer; color: #FC6B2D;" v-if="pageDetails.content.status == 'Created'" class="" @click="deletePaymentLink(pageRef)">{{ $t("button.cancel") }}</span>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.amount') }}:</td>
                        <td>{{ getFormattedNumber(pageDetails.content.amount, pageDetails.content.currency) }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.link_order_ref') }}:</td>
                        <td>{{ pageDetails.content.link_order_ref }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.payment_reference') }}:</td>
                        <td>
                          <div class="d-flex justify-content-start">
                            <p class="mb-0 my-auto">{{ pageRef }}</p>
                            <span class="ml-2" 
                                v-show="pageRef"
                                v-clipboard:copy="pageRef"
                                v-clipboard:success="onCopySuccess"
                                v-b-tooltip.hover.right
                                :title="$t('common.copy')"
                                style="cursor: pointer; width: 40px;height: 40px;background: rgba(245, 244, 243, 1);border-radius: 8px;position: relative;">
                              <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g opacity="0.7" clip-path="url(#clip0_1540_47398)">
                                  <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1540_47398">
                                    <rect width="18" height="18" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;" v-if="pageDetails.content.payment_page_ref">
                        <td>{{ $t('views.payment_links.payment_page_reference') }}:</td>
                        <td>
                        <router-link :to="{name: 'Payment Page Details', query: {pageRef: pageDetails.content.payment_page_ref, key: pageDetails.content.merchant_key}}">
                          {{ pageDetails.content.payment_page_ref }}
                        </router-link>
                          <span class="ml-2">
                            <i
                              v-show="pageDetails.content.payment_page_ref"
                              v-clipboard:copy="pageDetails.content.payment_page_ref"
                              v-clipboard:success="onCopySuccess2"
                              v-b-tooltip.hover.right
                              title="copy"
                              style="cursor: copy;"
                              class="mdi mdi-content-copy btn btn-lg p-0"
                            ></i>
                          </span>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.expiry') }}:</td>
                        <td v-if="pageDetails.content.source == 'invoice'">{{'N/A' }}</td>
                        <td v-else>{{ pageDetails.content.expired_at | dateFormat }}</td>
                      </tr>
                      <!-- <tr style="border-bottom: 1px #DCDAD5 solid;" v-if="choosenPaymentMethods > 0">
                        <td>Payment Methods</td>
                        <td>
                          <b-btn class="m-0" size="sm" variant="primary" v-b-modal.payment-methods-view>
                            View
                          </b-btn>
                          
                        </td>
                      </tr> -->
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.description') }}</td>
                        <td><div style="overflow-x: auto;" class="">{{ pageDetails.content.description }}</div></td>
                      </tr>
                      <tr v-if="pageDetails.content.customer_details.name || pageDetails.content.customer_details.phone_number || pageDetails.content.customer_details.email_address" style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.customer_name') }}</td>
                        <td>
                          {{ pageDetails.content.customer_details.name }}
                         <span v-if="pageDetails.content.customer_details.phone_number || pageDetails.content.customer_details.email_address"> 
                            ( <span style="color: #FC6B2D;" >
                              {{ pageDetails.content.customer_details.phone_number }}
                              <span v-if="pageDetails.content.customer_details.phone_number && pageDetails.content.customer_details.email_address">,</span> 
                              {{ pageDetails.content.customer_details.email_address }}</span> )
                          </span>
                        </td>
                      </tr>
                      <!-- <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.customer_phone') }}</td>
                        <td></td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.customer_email') }}</td>
                        <td></td>
                      </tr> -->
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.send_link') }}</td>
                        <td>
                          <div class="" id="single-merchant-cont" style="width:75px; cursor: default; display: inline-block;">
                                <div class="checkbox-group-custom">
                                  <input type="radio" id="single_entity" value="false">
                                  <label for="single_entity" class="position-relative mb-0"
                                  :class="{
                                      'filled': pageDetails.content.notify_by_email
                                    }">{{ $t('views.payment_links.table.fields.email') }}</label>
                                </div>
                              </div>
                              <div class="" id="master-merchant-cont" style="border-color: #DAD9D9; cursor: default; display: inline-block; margin-left: 40px;">
                                <div class="checkbox-group-custom">
                                  <input type="radio" id="master_entity" value="true">
                                  <label for="master_entity" class="position-relative mb-0"
                                  :class="{
                                      'filled': pageDetails.content.notify_by_phone
                                    }">{{ $t('views.payment_links.table.fields.sms') }}</label>
                                </div>
                              </div>
                        </td>
                      </tr>
                      <tr :key="index" v-for="(option, index) in pageDetails.content.notes" :data-key="index" style="border-bottom: 1px #DCDAD5 solid;" >
                        <td>{{ option.key }} </td>
                        <td>{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- <div class="col-md-6 p-3" style="background-color: #f5f5f5;">
                    <table class="page-details-2"> -->

                      <!-- <tr>
                        <td>Transactions:</td>
                        <td><span class="badge badge-primary">{{ totalRows }}</span></td>
                      </tr> -->
                    <!-- </table>
                  </div> -->

                  <b-modal id="payment-methods-view" v-if="choosenPaymentMethods > 0" no-close-on-backdrop hide-footer title="Payment Methods">
                    <div v-for="(pmtMethod, key, i) in pageDetails.content.chosen_payment_methods" :key="key">
                      <label class="text-primary mb-2">{{key}}</label>
                      <div v-for="(element, index) in pmtMethod" :key="index">
                        <b-img v-bind="{width: 40, height: 40, class: 'mx-2 my-1'}" :src="element.logo" rounded="circle" alt="Circle image"></b-img> <label class="text-primary my-auto">{{element.name}}</label>
                      </div>
                      <hr v-if="i !== (choosenPaymentMethods - 1)">
                    </div>
                  </b-modal>
              </div>
            </div>
          </div>

          <div class="row my-4">
            <!-- <div class="col-12" style="color: #252B3B;font-size: 18px;margin-bottom: 20px;">Transaction Attempts</div> -->
            <div class="col-sm-12 col-md-6 d-flex align-items-baseline">
              <!-- <div @click="resetAdvanceFilters">
                <FilterBtn :isActive="filterApplied()" v-b-toggle.transaction-filter-collapse />
              </div> -->
              <!-- <div id="tickets-table_length" class="dataTables_length ml-3">
                <label class="d-flex align-items-baseline">
                  Show&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;Entries
                </label>
              </div> -->
            </div>

            <!-- <div class="col-sm-12 col-md-6 d-flex justify-content-end align-items-baseline">
              <label class="mr-2" style="width: 100px;"> {{ 'Search by' }}</label>
              <b-form-select v-model="currentSelectedFilter" class="w-50" size="sm" :options="filterOptions"></b-form-select>&nbsp;
              <b-input-group class="w-50">
                <b-form-input type="search" v-model="refValueForFilter" @keyup="fetchTransactionOnEmptyFilter" @keyup.enter="filterTransactionBySelectedRefFilter" class="form-control form-control-sm ml-2" placeholder="Enter ReferenceID"></b-form-input>
                <b-input-group-append>
                <button class="btn btn-primary btn-block btn-sm" @click="filterTransactionBySelectedRefFilter">{{ $t('views.payments.transactions.search') }}</button>
                </b-input-group-append>
              </b-input-group>
            </div> -->
            <!-- Custom Search End-->
          </div>
            <b-table
              class="table-custom text-center table-centered"
              :items="pageTransactions"
              :fields="fields"
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-compare="sortByDate"
              sticky-header="50vh"
              show-empty
              :empty-text="$t('common.no-records')"
              busy.sync="true"
              hover
            >
              <template v-slot:head()="data">
                {{ $t(data.label) }}
              </template>
              <!-- <template v-slot:cell(createdAt)="{ value }">
                {{ value | date }}
              </template> -->
              <template #cell(createdAt)="row">
                  <p class="table-date">{{ row.value | date }}</p>
                  <p class="table-time">{{ row.value | time }}</p>
              </template>
              <template v-slot:cell(OrderRef)="row">
                <router-link
                  v-if="row.value"
                  style="color: #FC6B2D;"
                  :to="'/transactions/payments/payment-details?order_ref=' + row.value+'&key='+$route.query.key"
                >
                  {{row.item.MerchantOrderRef}}
                </router-link>
              </template>
              <template v-slot:cell(status)="row">
                <div
                  class="badge font-size-12"
                  :class="{'badge-soft-danger': `${row.value}` === 'Failed' || `${row.value}` === 'Expired',
                      'badge-soft-success': `${row.value}` === 'Success',
                      'badge-soft-warning': `${row.value}` === 'Initiated'}"
                >{{ row.value}}</div>
              </template>
            </b-table>
            <Spinner v-if="isFetchingTransactions === true"></Spinner>
          <div class="row">
            <div class="col">
              <!-- <div
                class="dataTables_paginate paging_simple_numbers float-right"
                style="padding-bottom: 20px;"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                    v-model="currentPage"
                    :current-page="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div> -->
              <div class="clear-both"></div>
            </div>
          </div>

          <!-- <div> -->
            <!-- Right Sidebar -->
            <!-- <div class="right-bar"
              :class="{
                'active': isAddPaymentLinkModalOpen && !isMasterMerchant
              }"> -->
              <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
              <!-- <div data-simplebar class="h-100" style="overflow-x: auto;"> -->
                <!-- <div class="rightbar-title">
                  <div class="right-top-section"> -->
                    <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
                    <!-- <div class="card-body-custom">
                      <i class="mdi mdi-close noti-icon float-right cursor-pointer" style="position: relative;z-index: 1;right: 15px;top: 15px;font-size: 20px;" @click="closePaymentLinkModal"></i>
                      <div class="media-body media-body-custom">
                        <p class="d-inline-block pl-4" style="font-size: 20px;margin-top: 30px;margin-bottom: 30px;">
                          {{$t('views.payment_links.create_pay_link')}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="px-4"> -->
                  <!-- <form id="modal" action="#" @submit.prevent="createRecon">
                    <div class="row">
                      <div class="col-lg-12">
                      </div>
                    </div>
                    <div class="row pb-3">

                    </div>
                    <div class="text-left" style="margin-top: 60px;">
                      <button
                        type="submit"
                        class="btn btn-primary dark-bg ml-1 col-4"
                      >
                        Submit
                      </button>
                      <b-button class="ml-2 col-4" variant="light" @click="closePaymentLinkModal"
                        >Cancel</b-button>
                    </div>
                  </form> -->
                  <!-- <PaymentLinkForm v-if="isAddPaymentLinkModalOpen && !isMasterMerchant" /> -->
                <!-- </div> -->
              <!-- </div> -->
              <!-- end slimscroll-menu-->
            <!-- </div> -->
              <!-- Right bar overlay-->
            <!-- <div @click="closePaymentLinkModal" class="rightbar-overlay"
              :class="{
                'active': isAddPaymentLinkModalOpen && !isMasterMerchant
            }"></div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
}
.page-details-1 td:first-child{
  height: 60px;
  color: #00000080;
  font-size: 15px;
  padding-left: 25px;
}
.page-details-1 td:last-child{
  font-size: 15px;
  color: #060606;
  position: relative;
  left: 15px;
}
.badge {
  font-size: 95% !important;
}
.custom-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  opacity: 1;
  color: #000000;
}
.checkbox-group-custom{
  position: relative;
}
.checkbox-group-custom input{
  display: none;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom{
  display: inline-block;
}
.checkbox-group-custom  label {
  cursor: default;;
}
.checkbox-group-custom  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group-custom  label.filled:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group-custom label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 4px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  /* background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%; */
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 600px;
  right: -600px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}

.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}
.top-section-header{
  background: #dcdad54d;
  padding: 15px 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px #DCDAD5 solid;
}
.banner-section{
  background-image: url("../../../assets/images/background_payment_link.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-content{
  border: 1px #ECEAE7 solid;
  border-radius: 15px;
  padding: 25px;
  position: relative;
  margin-bottom: 25px;
  margin-top: 15px;
}
.refunded{
    background: rgba(222, 53, 53, 0.10); 
    color: #DE3535;
}
.partial-refund{
    background: rgba(227, 179, 7, 0.10);
    color: #C59900;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
</style>